import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import PaymentOptionsTabs from "../components/PaymentOptionsTabs"
import favicon from "../images/favicon.ico"

import creditpaymentimg from "../images/marketing/svg/credit-payment/credit-payment-hero.svg"
import billingimg from "../images/marketing/svg/credit-payment/billing-made-easy.svg"
import submitapplicationimg from "../images/marketing/svg/credit-payment/submit-your-credit-application.png"
import requestcreditimg from "../images/marketing/svg/credit-payment/request-credit-increase.png"

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        Online Payments - Billing Made Easy - EzriRx.com - Marketplace Platform
        for 65,000+ Pharmaceuticals
      </title>
      <meta name="keywords" content="ezrirx, pharmacy purchasing platform" />
      <meta
        name="description"
        content="With EzriRx, billing is no longer a daunting task. We've gotten rid of the need for paperwork and have transferred all the information you need into one easily-accessible place."
      />
      <link rel="canonical" href="https://www.ezrirx.com/pharmacy-payment-management/" />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section className="hero-banner" id="hero-credit-payment">
          <div className="contents">
            <div className="hero-banner__inner">
              <div className="hero-content">
                <h1>
                  E-Signature customer applications to online payments and
                  invoicing.
                </h1>
                <p>
                  Payments are made in one of three ways: Terms, ACH (Direct
                  Deposit) and Credit Cards. We made it that simple.
                </p>
              </div>
              <div className="hero-image">
                <img
                  src={creditpaymentimg}
                  alt="E-Signature customer applications to online payments and invoicing."
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content">
          <div className="contents">
            <div className="single-col">
              <div className="col col--text">
                <div className="title-box">
                  <h2>Billing made easy!</h2>
                </div>
                <p>
                  With EzriRx, billing is no longer a daunting task. We've
                  gotten rid of the need for paperwork and have transferred all
                  the information you need into one easily-accessible place.
                  Time is money, and EzriRx is here to give back the time and
                  money you’ve wasted on ordering systems of the past!{" "}
                </p>
              </div>
              <div className="col col--img text-center">
                <img src={billingimg} alt="Billing made easy!"></img>
              </div>
            </div>
          </div>
        </section>

        <PaymentOptionsTabs />

        <section className="marketing-content">
          <div className="contents">
            <div className="table-2cols align-top table-2cols--mobile">
              <div className="col col--img text-center">
                <img
                  src={submitapplicationimg}
                  alt="Submit your credit application"
                  className="img-full"
                ></img>
              </div>
              <div className="col col--text">
                <div className="title-box">
                  <h2>Easily apply to wholesalers</h2>
                </div>
                <p>
                  EzriRx uses modern e-signature technology to allow you to
                  provide a single digital signature that we re-use to
                  automatically pre-fill each wholesaler’s customer application,
                  saving you from spending a considerable amount of time filling
                  out and signing forms.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content with-border">
          <div className="contents">
            <div className="table-2cols align-top">
              <div className="col col--text">
                <div className="title-box">
                  <h2>Receive credit and request credit limit increases</h2>
                </div>
                <p>
                  As wholesalers approve your applications, they can provide you
                  with credit and terms that you can use to quickly checkout
                  your orders. You can even request credit limit increases at
                  any time!
                </p>
              </div>
              <div className="col col--img text-center">
                <img
                  src={requestcreditimg}
                  alt="Request credit increase"
                  className="img-full"
                ></img>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </>
)
