import React from "react"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

import paywithachimg from "../images/marketing/svg/credit-payment/pay-with-ACH-illustration-2x.png"
import invoiceimg from "../images/marketing/svg/credit-payment/invoice-management-illustration-2x.png"
import transactionimg from "../images/marketing/svg/credit-payment/transaction-report-illustration-2x.png"

export default () => {
  const payYourWay = () => {
    return <span>Pay your way</span>
  }
  const invoiceManagement = () => {
    return <span>Invoice management</span>
  }
  const transactionsReport = () => {
    return <span>Transactions report</span>
  }

  return (
    <section className="marketing-utility">
      <div className="contents">
        <div className="marketing-utility__learn-more">
          <div className="title-box">
            <h2>Learn more about our payment system</h2>
          </div>
          <div className="utility__learn-more">
            <div className="tab-content utility__learn-more__content ez-marketing__content">
              <Tabs defaultActiveKey="step1" transition={false}>
                <Tab eventKey="step1" title={payYourWay()} id="ach">
                  <div
                    className="tab-pane active"
                    id="utility__learn-more__content--ach"
                  >
                    <p>
                      Pay for orders safely and securely on EzriRx using your
                      preferred method. Choose from Credit card, ACH, or
                      wholesaler terms.
                    </p>
                    <img src={paywithachimg} alt="Pay your way"></img>
                  </div>
                </Tab>
                <Tab eventKey="step2" title={invoiceManagement()} id="invoice">
                  <div
                    className="tab-pane"
                    id="utility__learn-more__content--invoice"
                  >
                    <p>
                      EzriRx saves all your invoices and keeps them all in one
                      place for access at any time. No more paperwork!
                    </p>
                    <img src={invoiceimg} alt="Invoice management"></img>
                  </div>
                </Tab>
                <Tab eventKey="step3" title={transactionsReport()}>
                  <div
                    className="tab-pane"
                    id="utility__learn-more__content--report"
                  >
                    <p>
                      You can access a detailed history of your transactions on
                      EzriRx at any time, so you are always in the clear about
                      your spending.
                    </p>
                    <img src={transactionimg} alt="Transactions report"></img>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
